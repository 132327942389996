import styled from "styled-components";

export const StPage = styled.div`
  & #speedMonitor {
    background: #000;
    color: #eee;
    font-weight: bold;
    font-family: 'Gill Sans', sans-serif;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  @media (prefers-color-scheme: dark) {
    & #speedMonitor {
      background: #eee;
      color: #000;
    }
  }

  & #speed {
    font-size: 30vh;
  }

  & #unit {
    font-size: 5vh;
    margin: 15vh 0 5vh 0;
  }

  & #time {
    font-size: 8vh;
    display: flex;
    align-items: center;
  }

  & #split {
    font-size: 5vh;
    margin: 0 10px;
  }

  @media screen and (orientation: landscape) {
    & #speedMonitor {
      flex-direction: row;
    }

    & #speed {
      font-size: 30vw;
    }

    & #unit {
      font-size: 3vw;
      margin: 0 10vh 0 15vh;
    }

    & #time {
      font-size: 5vw;
      flex-direction: column;
    }

    & #split {
      display: none;
    }
  }
`;