import React from "react";
import { StButton, StContainer } from "./Home.style";

const Home = () => {
    return (
        <StContainer>
            <StButton to="/chat">💬&nbsp;ChatGPT</StButton>
            <StButton to="/imgGen">🌄&nbsp;图片生成</StButton>
            <StButton to="/gac">🦄&nbsp;斗兽棋</StButton>
            <StButton to="/speed">🚀&nbsp;实时速度</StButton>
        </StContainer>
    );
};

export default Home;
