import React from 'react';
import { Link } from 'react-router-dom';

import { StHeader, StHeaderHolder } from './Header.style';

const Header = () => (
    <StHeaderHolder>
        <StHeader>
            <Link to="/">🏠&nbsp;Home</Link>
        </StHeader>
    </StHeaderHolder>
);

export default Header;
