import React, { useCallback, useEffect, useState } from 'react';
import { StPage } from './SpeedMonitor.style';

let isFull = false;

function swtichFull() {
    if (!isFull) {
        document.body.requestFullscreen();
        isFull = true;
    } else {
        document.exitFullscreen();
        isFull = false;
    }
};

const SpeedMonitor = () => {
    const [speed, setSpeed] = useState(0);
    const [now, setNow] = useState(['0', '0']);

    useEffect(() => {
        if (!navigator.geolocation) {
            alert('Current device is not supported');
            return;
        }

        function updateNow() {
            const n = new Date();
            setNow([n.getHours().toString(), n.getMinutes().toString()]);
        }
        updateNow();
        const timer = setInterval(updateNow, 1000);

        let lastPos: GeolocationPosition;
        navigator.geolocation.getCurrentPosition(
            (pos) => {
                if (!lastPos) {
                    lastPos = pos;
                }
            },
            (err) => {
                console.error(err);
            },
            { enableHighAccuracy: true }
        );

        const id = navigator.geolocation.watchPosition(
            (pos) => {
                if (!lastPos) {
                    lastPos = pos;
                    return;
                }
                const time = (pos.timestamp - lastPos.timestamp) / 1000;
                const { latitude: lat1, longitude: lon1 } = pos.coords;
                const { latitude: lat2, longitude: lon2 } = lastPos.coords;
                const distance = getDistance(lat1, lon1, lat2, lon2);
                const speedM = distance / (time || 1);
                const speedH = Math.floor(speedM / 3.6);
                setSpeed(speedH);
                lastPos = pos;
            },
            (err) => {
                console.error(err);
            },
            {
                enableHighAccuracy: true,
            }
        );

        return () => {
            navigator.geolocation.clearWatch(id);
            clearInterval(timer);
        };
    }, []);

    return (
        <StPage onClick={swtichFull}>
            <div id="speedMonitor">
                <div id="speed">{speed}</div>
                <div id="unit">km/h</div>
                <div id="time">
                    <div id="hour">{now[0].padStart(2, '0')}</div>
                    <div id="split">:</div>
                    <div id="minute">{now[1].padStart(2, '0')}</div>
                </div>
            </div>
        </StPage>
    );
}

export default SpeedMonitor;

function getDistance(lat1: number, lon1: number, lat2: number, lon2: number) {
    const R = 6371e3; // metres
    const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
    const φ2 = (lat2 * Math.PI) / 180;
    const Δφ = ((lat2 - lat1) * Math.PI) / 180;
    const Δλ = ((lon2 - lon1) * Math.PI) / 180;

    const a =
        Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // in metres
}