import React from 'react';
import { createHashRouter, RouterProvider } from 'react-router-dom';

import Home from './home/Home';
import Chat from './chat/Chat';
import ImgGen from './imgGen/ImgGen';
import AnimalChess from './animalChess/AnimalChess';
import SpeedMonitor from './speedMonitor/SpeedMonitor';

const router = createHashRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "chat",
      element: <Chat />,
    },
    {
        path: 'imgGen',
        element: <ImgGen />
    },
    {
        path: 'gac',
        element: <AnimalChess />
    },
    {
        path: 'speed',
        element: <SpeedMonitor />
    }
  ]);

function App() {
    return (
        <RouterProvider router={router} />
    );
}

export default App;
