import React, { useState, useCallback } from 'react';
import { Game } from './Game';
import { Button, Input } from 'antd';

const AnimalChess = () => {
    const [pwd, setPwd] = useState('');
    const [started, setStarted] = useState(false);
    const [online, setOnline] = useState(false);

    const onlineClick = useCallback(() => {
        if (!pwd) {
            return;
        }
        setOnline(true);
        setStarted(true);
    }, [pwd]);

    if (!started) {
        return (
            <div>
                <Input placeholder="房间密码（联机游戏）" value={pwd} onChange={e => setPwd(e.target.value)} />
                <Button onClick={onlineClick}>联机！</Button>
                <Button onClick={() => setStarted(true)}>本地游戏</Button>
            </div>
        )
    }
    return <Game online={online} roomId={pwd} />;
}

export default AnimalChess;
