import styled from "styled-components";

const headerSize = '60px';

export const StHeader = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: ${headerSize};
    padding: 0 20px;
    display: flex;
    align-items: center;
    font-size: 16px;
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px 0px;
    z-index: 999;
    background: #FFF;
`;

export const StHeaderHolder = styled.div`
    min-height: ${headerSize};
`;
