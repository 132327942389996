import React from 'react';
import { StButton, StButtons, StGameCanvas, StGamePage, StTag, StUser } from './Game.style';
import { AnimalChess } from 'libs';
import _ from 'lodash';
import { Modal, message } from 'antd';
import { io } from 'socket.io-client';

interface LocalChess {
    chess: AnimalChess.Chess;
    enable: boolean;
    boardX?: number;
    boardY?: number;
    currX?: number;
    currY?: number;
}

const enum GameStatus {
    NotStart,
    Start,
    End
}

export interface GameProps {
    roomId?: string;
    online?: boolean;
}

export class Game extends React.Component<GameProps, any> {
    private gameData = new AnimalChess.AnimalChessGame();

    private canvasRef = React.createRef<HTMLCanvasElement>();
    private ctx!: CanvasRenderingContext2D;

    private cacheWidth = 0;
    private cacheHeight = 0;
    private gridSize = 0;
    private chessSize = 0;
    private padding = 10;
    private top = 0;
    private left = 0;
    private moveSpeed = 1;

    private drawed = false;

    private chessObjMap: Record<string, LocalChess> = {};

    private selectedChessId = '';
    private avaliableSteps: string[] = [];

    private io!: ReturnType<typeof io>;

    private playerId = '';

    state = {
        chessRenderStamp: 0,
        allowSide: undefined,
        room: ({} as any),
        status: GameStatus.NotStart,
        ready: false
    };

    private get reverse() {
        return this.state.allowSide != null && this.state.allowSide === AnimalChess.SideType.A;
    }

    componentDidMount(): void {
        this.initCanvas();
        if (this.props.online && this.props.roomId) {
            this.initIO();
        } else {
            this.setState({ room: { player1: 2, player2: 2 }, status: GameStatus.Start });
        }
    }

    private initCanvas() {
        const canvas = this.canvasRef.current;
        if (!canvas) {
            return;
        }
        this.updateSize(canvas);
        this.resetChessMap();
        this.ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
        this.draw();
        this.drawed = true;

        window.addEventListener('resize', _.debounce(() => this.updateSize(canvas)));
    }

    private initIO() {
        if (this.io) {
            this.io.disconnect();
        }
        const link = `http://${process.env.NODE_ENV === 'development' ? 'localhost:3001' : '20.39.194.164:3000'}`;
        this.io = io(link);
        this.io.on('disconnect', () => {
            message.error('网络连接已断开');
        });

        const on = (event: string, listener: (...args: any[]) => void) => this.io.on(event, listener);

        on('joined', (pid: string) => {
            if (!pid) {
                message.error('加入失败！');
                return;
            }
            const allowSide = pid == '1' ? AnimalChess.SideType.A : AnimalChess.SideType.B;
            this.playerId = pid;
            this.setState({ allowSide });
            message.success('已加入房间！');
        });
        on('joinFailed', () => {
            message.error('加入失败!!');
        });
        on('updateRoom', (data) => {
            const room = JSON.parse(data);
            const player = this.playerId == '1' ? room.player1 : room.player2;
            const status = room.playing ? GameStatus.Start : GameStatus.NotStart;
            this.setState({ room , ready: player == '2', status });
        });
        on('updateGame', (data) => {
            this.gameData.forceUpdate(data);
            this.resetChessMap(true);
            this.forceUpdate();
            if (this.gameData.isOver) {
                Modal.info({ title: "GAME OVER!" });
                this.resetGame();
            }
        });
        this.emit('join', this.props.roomId);
    }

    emit = (event: string, ...args: any[]) => {
        if (!this.io) {
            return;
        }
        this.io.emit(`animalChess/${event}`, ...args);
    }

    private resetGame() {
        this.gameData = new AnimalChess.AnimalChessGame();
        this.resetChessMap(false);
    }

    private resetChessMap(doDiff = false) {
        for (const chess of Object.values(this.gameData.data.chessMap)) {
            const old = this.chessObjMap[chess.id];
            let currX: number | undefined = undefined;
            let currY: number | undefined = undefined;
            if (old && doDiff && chess.x >= 0 &&
                (chess.x !== old.boardX || chess.y !== old.boardY)
            ) {
                [currX, currY] = this.getCanvasCoor(old.boardX!, old.boardY!);
            }
            this.chessObjMap[chess.id] = {
                chess,
                boardX: chess.x,
                boardY: chess.y,
                enable: chess.x >= 0,
                currX,
                currY
            };
        }
    }

    private updateSize(canvas: HTMLCanvasElement) {
        canvas.width = window.innerWidth * window.devicePixelRatio;
        canvas.height = (window.innerHeight - 100) * window.devicePixelRatio;
        const canvasWidth = canvas.width;
        const canvasHeight = canvas.height;
        if (this.cacheWidth === canvasWidth && this.cacheHeight === canvasHeight) {
            return;
        }
        this.cacheWidth = canvasWidth;
        this.cacheHeight = canvasHeight;
        let gridSize = 0;
        const width = canvasWidth - this.padding * 2;
        const height = canvasHeight - this.padding * 2;
        if (width / 7 >= height / 9) {
            gridSize = height / 9;
        } else {
            gridSize = width / 7;
        }
        this.gridSize = gridSize;
        this.chessSize = gridSize * 0.7;
        this.top = (canvasHeight - gridSize * 9) / 2;
        this.left = (canvasWidth - gridSize * 7) / 2;
        this.moveSpeed = gridSize / 30;
    }

    private draw() {
        if (!this.ctx) {
            return;
        }
        const ctx = this.ctx;
        ctx.clearRect(0, 0, this.cacheWidth, this.cacheHeight);
        this.drawBoard();
        this.drawChesses();
        this.drawAvaliableSteps();
        requestAnimationFrame(() => this.draw());
    }

    private drawBoard() {
        const ctx = this.ctx;
        ctx.save();
        ctx.strokeStyle = "#000";
        ctx.lineWidth = 1;
        ctx.font = `${this.gridSize / 2}px serif`;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';

        const board = this.gameData.data.grid;
        for (let i = 0; i < board.length; ++i) {
            for (let j = 0; j < board[i].length; ++j) {
                const grid = board[i][j];
                const [x, y] = this.getCanvasCoor(i, j);
                const color = getGridColor(grid.type, grid.side);
                if (color) {
                    ctx.fillStyle = color;
                    ctx.fillRect(x, y, this.gridSize, this.gridSize);
                }
                ctx.strokeRect(x, y, this.gridSize, this.gridSize);
                const text = gridTextMap[grid.type];
                if (!text) {
                    continue;
                }
                const [wo, ho] = [(this.gridSize) / 2, (this.gridSize) / 2];
                if (grid.side === AnimalChess.SideType.A) {
                    ctx.translate(x + wo, y + ho);
                    ctx.rotate(Math.PI);
                    ctx.fillText(text, 0, 0);
                    ctx.setTransform(1, 0, 0, 1, 0, 0);
                } else {
                    ctx.fillText(text, x + wo, y + ho);
                }
            }
        }
        ctx.restore();
    }

    private drawChesses() {
        const ctx = this.ctx;
        ctx.save();
        ctx.lineWidth = this.chessSize / 8;
        ctx.fillStyle = '#FFF';
        ctx.font = `${this.gridSize / 2}px serif`;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';

        const chesses = Object.entries(this.chessObjMap);
        const radius = this.chessSize / 2;
        const offset = this.gridSize / 2;
        for (const [k, v] of chesses) {
            const { chess, enable, currX, currY } = v;
            if (!enable) {
                continue;
            }
            const isSideA = chess.side === AnimalChess.SideType.A;
            const [tx, ty] = this.getCanvasCoor(chess.x, chess.y);
            let x: number;
            let y: number;
            if (currX != null && currY != null) {
                const newX = currX + direction(currX, tx) * this.moveSpeed;
                const newY = currY + direction(currY, ty) * this.moveSpeed;
                if (Math.abs(tx - newX) > 0.1 || Math.abs(ty - newY) > 0.1) {
                    x = newX;
                    y = newY;
                    this.chessObjMap[k].currX = newX;
                    this.chessObjMap[k].currY = newY;
                } else {
                    x = tx;
                    y = ty;
                    this.chessObjMap[k].currX = undefined;
                    this.chessObjMap[k].currY = undefined;
                }
            } else {
                x = tx;
                y = ty;
            }
            ctx.beginPath();
            ctx.arc(x + offset, y + offset, radius, 0, 360);
            ctx.strokeStyle = isSideA ? sideAColor : sideBColor;
            ctx.fill();
            ctx.stroke();

            const [wo, ho] = [(this.gridSize) / 2, (this.gridSize) / 2];
            if (isSideA) {
                ctx.translate(x + wo, y + ho);
                ctx.rotate(Math.PI);
                ctx.fillText(chessTextMap[chess.type], 0, 0);
                ctx.setTransform(1, 0, 0, 1, 0, 0);
            } else {
                ctx.fillText(chessTextMap[chess.type], x + wo, y + ho);
            }
        }
        ctx.restore();
    }

    private drawAvaliableSteps() {
        if (!this.avaliableSteps.length) {
            return;
        }
        const ctx = this.ctx;
        ctx.save();
        ctx.strokeStyle = 'red';
        ctx.lineWidth = 2;
        const size = this.gridSize * 0.8;
        const offset = (this.gridSize - size) / 2;
        for (const step of this.avaliableSteps) {
            const [sx, sy] = step.split(',');
            const [x, y] = this.getCanvasCoor(+sx, +sy);
            ctx.strokeRect(x + offset, y + offset, size, size);
        }
        ctx.restore();
    }

    private getCanvasCoor(x: number, y: number): [left: number, top: number] {
        return [y * this.gridSize + this.left, x * this.gridSize + this.top];
    }

    private pickChess(chessId: string) {
        this.selectedChessId = chessId;
        this.avaliableSteps = this.gameData.getNextSteps(chessId).map(([x, y]) => `${x},${y}`);
    }

    private unpickChess() {
        this.selectedChessId = '';
        this.avaliableSteps = [];
    }

    private move(chessId: string, x: number, y: number) {
        if (this.props.online) {
            this.emit('move', chessId, x, y);
            return false;
        } else {
            const res = this.gameData.move(chessId, x, y);
            this.resetChessMap(true);
            this.setState({ chessRenderStamp: +new Date() });
            return res;
        }
    }

    private onClick = (e: any) => {
        if (!this.drawed || this.gameData.over || this.state.status !== GameStatus.Start) {
            return;
        }
        if (this.state.allowSide != null && this.gameData.currentPlayer !== this.state.allowSide) {
            return;
        }
        const { left, top, width, height } = this.canvasRef.current!.getBoundingClientRect();
        let { clientX, clientY } = e;
        if (this.reverse) {
            clientX = width - clientX + left;
            clientY = height - clientY + top;
        } else {
            clientX = clientX - left;
            clientY = clientY - top;
        }
        const [ex, ey] = [clientX * window.devicePixelRatio, clientY * window.devicePixelRatio];
        let [cx, cy] = [
            Math.floor((ey - this.top) / this.gridSize),
            Math.floor((ex - this.left) / this.gridSize)
        ];
        if (cx < 0 || cx > 8 || cy < 0 || cy > 6) {
            return;
        }
        const clickedChessId = this.gameData.data.grid[cx][cy].chessId;
        if (this.selectedChessId) {
            if (this.avaliableSteps.includes(`${cx},${cy}`)) {
                const isOver = this.move(this.selectedChessId, cx, cy);
                this.unpickChess();
                if (isOver) {
                    Modal.info({ title: "GAME OVER!" });
                    this.resetGame();
                }
                return;
            }
            if (clickedChessId && this.gameData.canPick(clickedChessId)) {
                this.pickChess(clickedChessId);
            } else {
                this.unpickChess();
            }
        } else {
            if (!clickedChessId || !this.gameData.canPick(clickedChessId)) {
                return;
            }
            this.pickChess(clickedChessId);
        }
    };

    private onReadyClick = () => {
        this.emit('ready', !this.state.ready);
    };

    renderTag() {
        if (this.state.status === GameStatus.Start) {
            return <StTag>当前回合</StTag>;
        }
        return null;
    }



    render() {
        const reverse = this.reverse;
        const isSideA = this.gameData.currentPlayer === AnimalChess.SideType.A;
        return (
            <StGamePage>
                <StGameCanvas
                    ref={this.canvasRef}
                    reverse={reverse}
                    onClick={this.onClick}
                />
                <StUser top={!reverse} background={sideAColor}>
                    {this.state.room.player1 ? `momo${this.state.room.player1 == 1 ? ' 未准备': ''}` : '--- 未加入 ---'}
                    {isSideA && this.renderTag()}
                </StUser>
                <StUser top={reverse} background={sideBColor}>
                    {this.state.room.player2 ? `omom${this.state.room.player2 == 1 ? ' 未准备': ''}` : '--- 未加入 ---'}
                    {!isSideA && this.renderTag()}
                </StUser>

                {(this.props.online && this.state.status !== GameStatus.Start) && (
                    <StButtons>
                        <StButton onClick={this.onReadyClick}>{this.state.ready ? '取消' : ''}准备</StButton>
                    </StButtons>
                )}
            </StGamePage>
        );
    }
};

function direction(a1: number, a2: number) {
    const d = a2 - a1;
    return d === 0 ? 0 : d / Math.abs(d);
}

const chessTextMap = {
    [AnimalChess.ChessType.Elephant]: '🐘',
    [AnimalChess.ChessType.Lion]: '🦁',
    [AnimalChess.ChessType.Tiger]: '🐯',
    [AnimalChess.ChessType.Leopard]: '🐆',
    [AnimalChess.ChessType.Wolf]: '🐺',
    [AnimalChess.ChessType.Dog]: '🐶',
    [AnimalChess.ChessType.Cat]: '😺',
    [AnimalChess.ChessType.Rat]: '🐭',
};

const sideAColor = '#e8796a';
const sideBColor = '#e2b56c';

const getGridColor = (gridType?: AnimalChess.GridType, side?: AnimalChess.SideType) => {
    if (gridType === AnimalChess.GridType.River) {
        return "#007acc";
    }
    if (gridType === AnimalChess.GridType.Home) {
        return side === AnimalChess.SideType.A ? sideAColor : sideBColor;
    }
    return "";
};

const gridTextMap: any = {
    [AnimalChess.GridType.Home]: '🏠',
    [AnimalChess.GridType.Trap]: '🕸️'
};
