"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Game = void 0;
const board_1 = require("./board");
const types_1 = require("./types");
class Game extends board_1.Board {
    constructor() {
        super(...arguments);
        this.isOver = false;
        this.currentSide = types_1.SideType.A;
    }
    get currentPlayer() {
        return this.currentSide;
    }
    get over() {
        return this.isOver;
    }
    canPick(chessId) {
        const chess = this.chessMap[chessId];
        return chess.side === this.currentSide;
    }
    move(chessId, x, y) {
        if (this.isOver) {
            return;
        }
        const chess = this.chessMap[chessId];
        if (chess.side !== this.currentSide || !this.canMove(chessId, x, y)) {
            return;
        }
        this.isOver = this.moveChess(chessId, x, y) || this.checkOver();
        this.lastMove = [x, y];
        this.currentSide = this.currentSide === types_1.SideType.A ? types_1.SideType.B : types_1.SideType.A;
        return this.isOver;
    }
    get data() {
        return {
            grid: this.grid,
            chessMap: this.chessMap,
            currentSide: this.currentSide,
            lastMove: this.lastMove
        };
    }
    forceUpdate(jsonData) {
        const data = JSON.parse(jsonData);
        if (!data) {
            return;
        }
        this.currentSide = data.currentSide;
        this.grid = data.grid;
        this.chessMap = data.chessMap;
        this.isOver = data.isOver;
    }
}
exports.Game = Game;
