const host = 'http://20.39.194.164:3000/openai';
// const host = 'http://localhost:3001/openai';

export async function post(url: string, data: any): Promise<any> {
    const res = await fetch(`${host}${url}`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
    const json = await res.json();
    if (json.success) {
        return json.data;
    }
    throw new Error(json.data);
}