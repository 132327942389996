import styled from "styled-components";
import { Link } from 'react-router-dom';

export const StContainer = styled.div`
    padding: 20px;
    display: flex;
    flex-direction: column;
    overflow: auto
`

export const StButton = styled(Link)`
    padding: 25px 20px;
    margin-bottom: 20px;
    border-radius: 4px;
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px 0px;

    &:hover {
        box-shadow: rgb(0 0 0 / 15%) 0px 0px 5px 1px;
    }
`;
