import { Slider } from "antd";
import styled from "styled-components";

export const StContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
`;

export const StInputRow = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
    margin-top: 10px;
    flex-wrap: wrap;
`;

export const StSlider = styled(Slider)`
    flex: 1;
    margin: 0 15px;
`;

export const StImageContainer = styled.div`
    padding: 0 5px;
    display: flex;
    flex-wrap: wrap;
`;

export const StImage = styled.img`
    height: 256px;
    wdith: 256px;
    object-fit: contain;
    margin: 10px;
    border-radius: 3px;
    backgroud: lightgray;
`;
