import styled from "styled-components";

const StMessage = styled.div`
    max-width: 80%;
    padding: 10px;
    border-radius: 4px;
    margin: 5px;
    font-size: 14px;
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px 0px;
    
    & img {
        background: lightgray;
        borer-radius: 2px;
        min-height: 50px;
        min-width: 100px;
        object-fit: contain;
    }
`;

export const StRequest = styled(StMessage)`
    align-self: flex-end;
    background: #eaf5ea;
`;

export const StResponse = styled(StMessage)`
    align-self: flex-start;
    background: #f8f8f8;
    padding: 0 10px;
`;

export const StNotify = styled.div`
    text-align: center;
    opacity: 0.5;
    font-size: 12px;
`;
