"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnimalChess = void 0;
const game_1 = require("./game");
const types_1 = require("./types");
var AnimalChess;
(function (AnimalChess) {
    class AnimalChessGame extends game_1.Game {
    }
    AnimalChess.AnimalChessGame = AnimalChessGame;
    ;
    AnimalChess.SideType = types_1.SideType;
    AnimalChess.ChessType = types_1.ChessType;
    AnimalChess.GridType = types_1.GridType;
})(AnimalChess = exports.AnimalChess || (exports.AnimalChess = {}));
