"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BoardTest = exports.Board = void 0;
const constant_1 = require("./constant");
const types_1 = require("./types");
class Board {
    constructor() {
        this.reset();
    }
    static chessId(side, type) {
        return `${side}-${type}`;
    }
    static createEmptyGrid() {
        const res = [];
        for (let i = 0; i < 9; ++i) {
            const row = [];
            for (let j = 0; j < 7; ++j) {
                const [type, side] = constant_1.InitGrid[`${i},${j}`] || [];
                row.push({
                    type: type || types_1.GridType.None,
                    side
                });
            }
            res.push(row);
        }
        return res;
    }
    static isHome(x, y) {
        return (x === 0 || x === 8) && y === 3;
    }
    static isRiver(x, y) {
        return (y === 1 || y === 2 || y === 4 || y === 5) && (2 < x && x < 6);
    }
    static isTrapped(x, y, side) {
        let targetX = -1;
        if (y === 2 || y === 4) {
            targetX = side === types_1.SideType.A ? 8 : 0;
        }
        else if (y === 3) {
            targetX = side === types_1.SideType.A ? 7 : 1;
        }
        return targetX >= 0 && targetX === x;
    }
    reset() {
        this.grid = Board.createEmptyGrid();
        this.chessMap = {};
        for (const [x, y, type, side] of constant_1.InitChess) {
            const id = Board.chessId(side, type);
            const chess = {
                id,
                side,
                type,
                x,
                y
            };
            this.chessMap[id] = chess;
            this.grid[x][y].chessId = id;
        }
    }
    // check can A eat B
    canEat(chessIdA, chessIdB) {
        const chessA = this.chessMap[chessIdA];
        const chessB = this.chessMap[chessIdB];
        if (chessA.side === chessB.side) {
            return false;
        }
        // check river/land status
        const inRiverA = Board.isRiver(chessA.x, chessA.y);
        const inRiverB = Board.isRiver(chessB.x, chessB.y);
        if (inRiverA !== inRiverB) {
            return false;
        }
        else if (inRiverA) {
            // two rats can eat each other in river
            return true;
        }
        // now we're all on land
        if (Board.isTrapped(chessB.x, chessB.y, chessB.side)) {
            return true;
        }
        if (chessA.type === types_1.ChessType.Rat && chessB.type === types_1.ChessType.Elephant) {
            return true;
        }
        else if (chessA.type === types_1.ChessType.Elephant && chessB.type === types_1.ChessType.Rat) {
            return false;
        }
        return chessA.type > chessB.type;
    }
    checkOver() {
        let hasA = false;
        let hasB = false;
        for (const chess of Object.values(this.chessMap)) {
            if (chess.x < 0) {
                continue;
            }
            if (chess.side === types_1.SideType.A) {
                hasA = true;
            }
            else {
                hasB = true;
            }
            if (hasA && hasB) {
                return false;
            }
        }
        return true;
    }
    // no movable validate here
    // return is game over or not
    moveChess(chessId, x, y) {
        const ateChessId = this.grid[x][y].chessId;
        if (ateChessId) {
            const ateChess = this.chessMap[ateChessId];
            ateChess.x = -1;
            ateChess.y = -1;
        }
        const chess = this.chessMap[chessId];
        this.grid[chess.x][chess.y].chessId = undefined;
        chess.x = x;
        chess.y = y;
        this.grid[x][y].chessId = chess.id;
        return Board.isHome(x, y);
    }
    // can move go to (x, y)
    canMove(chessId, targetX, targetY) {
        const checkList = this.getNextSteps(chessId);
        for (const [x, y] of checkList) {
            if (targetX === x && targetY === y) {
                return true;
            }
        }
        return false;
    }
    getNextSteps(chessId) {
        const chess = this.chessMap[chessId];
        if (!chess || chess.x < 0) {
            return [];
        }
        return this.getNextPosition(chess);
    }
    getNextPosition(chess) {
        const res = [];
        const { id, type, x, y, side } = chess;
        const canJump = type === types_1.ChessType.Tiger || type === types_1.ChessType.Lion;
        const canSwin = type === types_1.ChessType.Rat;
        const queue = [[x - 1, y], [x + 1, y], [x, y - 1], [x, y + 1]];
        while (queue.length) {
            const head = queue.shift();
            const [nx, ny, fromJump] = head;
            if (nx < 0 || nx > 8 || ny < 0 || ny > 6) {
                continue;
            }
            const grid = this.grid[nx][ny];
            if (grid.type === types_1.GridType.Trap || grid.type === types_1.GridType.None) {
                if (!grid.chessId || this.canEat(id, grid.chessId)) {
                    res.push([nx, ny]);
                }
                if (fromJump && type === types_1.ChessType.Lion) {
                    const dx = d(nx - x);
                    const dy = d(ny - y);
                    queue.push([nx + dx, ny + dy]);
                }
                continue;
            }
            if (grid.type === types_1.GridType.Home) {
                if (grid.side !== side) {
                    res.push([nx, ny]);
                }
                continue;
            }
            if (canSwin) {
                if (!grid.chessId || this.canEat(id, grid.chessId)) {
                    res.push([nx, ny]);
                }
                continue;
            }
            if (!canJump) {
                continue;
            }
            if (grid.chessId) {
                const chessInRiver = this.chessMap[grid.chessId];
                if (chessInRiver.side !== side) {
                    continue;
                }
            }
            const dx = d(nx - x);
            const dy = d(ny - y);
            queue.push([nx + dx, ny + dy, true]);
        }
        return res;
    }
}
exports.Board = Board;
class BoardTest extends Board {
    constructor() {
        super();
        this.grid = Board.createEmptyGrid();
    }
    forceMove(chessId, x, y) {
        const chess = this.chessMap[chessId];
        if (0 <= x && x <= 8 && 0 <= y && y <= 6) {
            this.grid[chess.x][chess.y].chessId = undefined;
        }
        this.grid[x];
        chess.x = x;
        chess.y = y;
        this.grid[x][y].chessId = chessId;
    }
    forceRemove(chessId) {
        const chess = this.chessMap[chessId];
        this.grid[chess.x][chess.y].chessId = undefined;
    }
}
exports.BoardTest = BoardTest;
function d(n) {
    return Math.trunc(n / 9 + (n > 0 ? 0.9 : -0.9));
}
