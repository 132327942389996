import styled from "styled-components";

export const StGamePage = styled.div`
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    position: relative;
`;

export const StGameCanvas = styled.canvas<{ reverse?: boolean }>`
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    height: calc(100% - 100px);
    transform: ${x => x.reverse ? "rotate(180deg)" : "unset"};
`;

export const StUser = styled.div<{ top?: boolean; background: string }>`
    position: absolute;
    left: 0;
    right: 0;
    height: 50px;
    ${x => x.top ? "top: 0" : "bottom: 0"};
    background: ${x => x.background};
    color: #FFF;
    display: flex;
    align-items: center;
    padding: 0 10px;
    font-weight: bold;
`;

export const StTag = styled.div`
    background: #007acc;
    color: #FFF;
    font-size: 12px;
    margin-left: 10px;
    padding: 2px 5px;
    border-radius: 5px;
`;

export const StButtons = styled.div`
    position: fixed;
    right: 10px;
    bottom: 10px;
`;

export const StButton = styled.div`
    background: #519aba;
    color: #FFF;
    font-size: 14px;
    margin-left: 10px;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
`;
