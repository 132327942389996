import React from 'react';
import ReactMarkdown from 'react-markdown';
import { StRequest, StResponse, StNotify } from "./Message.style";

export const enum MessageType {
    Unknown,
    Notify,
    Request,
    Response
}

export interface MessageData {
    type: MessageType;
    content: string;
    id: number;
}

export const Message = (props: { message: MessageData }) => {
    const { message } = props;
    if (message.type === MessageType.Request) {
        return (<StRequest>{message.content}</StRequest>);
    }
    if (message.type === MessageType.Response) {
        return (
            <StResponse>
                <ReactMarkdown>{message.content}</ReactMarkdown>
            </StResponse>
        );
    }
    if (message.type === MessageType.Notify) {
        return (<StNotify>{message.content}</StNotify>);
    }
    return null;
}