"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InitGrid = exports.InitChess = void 0;
const types_1 = require("./types");
exports.InitChess = [
    [0, 0, types_1.ChessType.Lion, types_1.SideType.A],
    [0, 6, types_1.ChessType.Tiger, types_1.SideType.A],
    [1, 1, types_1.ChessType.Dog, types_1.SideType.A],
    [1, 5, types_1.ChessType.Cat, types_1.SideType.A],
    [2, 0, types_1.ChessType.Rat, types_1.SideType.A],
    [2, 2, types_1.ChessType.Leopard, types_1.SideType.A],
    [2, 4, types_1.ChessType.Wolf, types_1.SideType.A],
    [2, 6, types_1.ChessType.Elephant, types_1.SideType.A],
    [8, 0, types_1.ChessType.Tiger, types_1.SideType.B],
    [8, 6, types_1.ChessType.Lion, types_1.SideType.B],
    [7, 1, types_1.ChessType.Cat, types_1.SideType.B],
    [7, 5, types_1.ChessType.Dog, types_1.SideType.B],
    [6, 0, types_1.ChessType.Elephant, types_1.SideType.B],
    [6, 2, types_1.ChessType.Wolf, types_1.SideType.B],
    [6, 4, types_1.ChessType.Leopard, types_1.SideType.B],
    [6, 6, types_1.ChessType.Rat, types_1.SideType.B],
];
exports.InitGrid = {
    '0,2': [types_1.GridType.Trap, types_1.SideType.A],
    '0,3': [types_1.GridType.Home, types_1.SideType.A],
    '0,4': [types_1.GridType.Trap, types_1.SideType.A],
    '1,3': [types_1.GridType.Trap, types_1.SideType.A],
    '3,1': [types_1.GridType.River],
    '3,2': [types_1.GridType.River],
    '3,4': [types_1.GridType.River],
    '3,5': [types_1.GridType.River],
    '4,1': [types_1.GridType.River],
    '4,2': [types_1.GridType.River],
    '4,4': [types_1.GridType.River],
    '4,5': [types_1.GridType.River],
    '5,1': [types_1.GridType.River],
    '5,2': [types_1.GridType.River],
    '5,4': [types_1.GridType.River],
    '5,5': [types_1.GridType.River],
    '7,3': [types_1.GridType.Trap, types_1.SideType.B],
    '8,2': [types_1.GridType.Trap, types_1.SideType.B],
    '8,3': [types_1.GridType.Home, types_1.SideType.B],
    '8,4': [types_1.GridType.Trap, types_1.SideType.B]
};
