import React, { useCallback, useState } from 'react';
import { Col, Input, Row, Select } from 'antd';

import { StContainer, StImage, StImageContainer, StInputRow, StSlider } from './ImgGen.style';
import Header from '../../components/Header/Header';
import { post } from '../../libs/request';

const { Search } = Input;

const sizeOptions = [256, 512, 1024].map(el => ({ value: `${el}x${el}`, label: `${el}px` }));

const ImgGen = () => {
    const [loading, setLoading] = useState(false);
    const [n, setN] = useState(3);
    const [size, setSize] = useState('512x512');
    const [images, setImages] = useState<string[]>([]);

    const onSlideChange = useCallback((v: number) => setN(v), []);

    const onSearch = useCallback(async (text: string) => {
        try {
            setLoading(true);
            const res = await post('/imgGen', {
                prompt: text,
                n,
                size
            });
            setImages(res);
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }, [n, size]);

    return (
        <StContainer>
            <Header />
            <Row>
                <Col span={6} sm={12} xs={12}>
                    <StInputRow>
                        Count
                        <StSlider
                            value={n}
                            max={10}
                            min={1}
                            step={1}
                            tooltip={{ open: false }}
                            onChange={onSlideChange}
                        />
                        {n}
                    </StInputRow>
                </Col>
                <Col span={6} sm={12} xs={12}>
                    <StInputRow>
                        Size&nbsp;
                        <Select size="small" style={{ width: 90 }} value={size} onChange={setSize} options={sizeOptions} />
                    </StInputRow>
                </Col>
            </Row>
            <StInputRow>
                <Search
                    placeholder="input text"
                    enterButton="Go"
                    loading={loading}
                    onSearch={onSearch}
                />
            </StInputRow>
            <StImageContainer>
                {images.map(el => (
                    <a key={el} href={el} target="_blank" rel="noreferrer" >
                        <StImage src={el} />
                    </a>
                ))}
            </StImageContainer>
        </StContainer>
    );
};

export default ImgGen;
