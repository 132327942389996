"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChessType = exports.GridType = exports.SideType = void 0;
var SideType;
(function (SideType) {
    SideType[SideType["A"] = 0] = "A";
    SideType[SideType["B"] = 1] = "B";
})(SideType = exports.SideType || (exports.SideType = {}));
var GridType;
(function (GridType) {
    GridType[GridType["None"] = 0] = "None";
    GridType[GridType["Home"] = 1] = "Home";
    GridType[GridType["Trap"] = 2] = "Trap";
    GridType[GridType["River"] = 3] = "River";
})(GridType = exports.GridType || (exports.GridType = {}));
var ChessType;
(function (ChessType) {
    ChessType[ChessType["Rat"] = 0] = "Rat";
    ChessType[ChessType["Cat"] = 1] = "Cat";
    ChessType[ChessType["Dog"] = 2] = "Dog";
    ChessType[ChessType["Wolf"] = 3] = "Wolf";
    ChessType[ChessType["Leopard"] = 4] = "Leopard";
    ChessType[ChessType["Tiger"] = 5] = "Tiger";
    ChessType[ChessType["Lion"] = 6] = "Lion";
    ChessType[ChessType["Elephant"] = 7] = "Elephant";
})(ChessType = exports.ChessType || (exports.ChessType = {}));
