import styled from 'styled-components';

export const StContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const StContent = styled.div`
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding: 10px;
`;

export const StInputContainer = styled.div`
    padding: 15px;
    background: #e1e1e1;
    display: flex;
`;

export const StInputButtons = styled.div`
    margin-right: 10px;
`;